<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <frp-logo />

        <h2 class="brand-text text-primary ml-1">FA</h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img :src="imgUrl" fluid alt="Register V2" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            OTP 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Please enter the OTP sent to the registered mail
          </b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <b-row>
                <b-col sm="12" class="mt-1">
                  <inputtext
                    class=""
                    name="OTP Code"
                    :valuex="appData.otp"
                    keys="key"
                    @updatedata="(val) => (appData.otp = val)"
                    placeholder="Enter OTP Code"
                    label="OTP Code"
                    validations="required|digits:4"
                  ></inputtext>
                </b-col>
              </b-row>
              <div class="d-flex mt-1">
                <b-button block type="submit" variant="primary">
                  Verify
                </b-button>
              </div>
              <div class="d-flex mt-1">
                <b-button
                  block
                  type="button"
                  @click="resendOTP"
                  variant="outline-primary"
                >
                  Resend OTP
                </b-button>
              </div>
              <p class="text-center mt-2">
                <b-button v-on:click="logout" type="button">
                  <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-button>
              </p>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputtext from "@/views/Component/Input/inputtext.vue";
import axios from "@axios";
import FrpLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { required } from "@validations";
import router from "@/router";

import { initialAbility } from "@/libs/acl/config";
import { useToast } from "vue-toastification/composition";
import store from "@/store/index";
import StoreModule from "./StoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import formValidation from "@core/comp-functions/forms/form-validation";

import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    FrpLogo,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    inputtext,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      required,
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "auth-otp";
    const modulename = "OTP Verification";
    const modulenamesub = "OTP Verification";

    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    const blankAppData = {
      otp: "",
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));

    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const onSubmit = () => {
      if (appData.value.otp.length < 4) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: "Enter a Valid OTP",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return "";
      }
      axios
        .post("v1/otpverify", { otp: appData.value.otp })
        .then((response) => {
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });

          const userData = getUserData();

          router
            .push({
              name: "dashboard-analytics",
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            appData.value.otp = null;
            console.log(error.response.data.message);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: error.response.data.message || "",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };
    const resendOTP = () => {
      axios
        .post("v1/otpresend")
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text:
                modulename +
                (edittype == 1 ? " editted" : " added") +
                " successfully",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value.otp = null;
            console.log(error.response.data.message);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          appData.value = response.data.data;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      today,
      resendOTP,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/reset-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userData");
      this.$ability.update(initialAbility);
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
